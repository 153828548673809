import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'

import PageNotFound from "@/views/PageNotFound";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  { path: '/page-not-found',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/page-not-found"
  },
  {
    path: '/secret',
    name: 'Secret',
    component: () => import('../views/Secret.vue')
  },
  {
    path: '/sport',
    name: 'Sport',
    component: () => import('../views/Sport.vue')
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('../views/Tasks.vue')
  },
  {
    path: '/tasks/:id',
    name: 'TasksID',
    component: () => import('../views/TasksID.vue'),
    props: true
  },
  {
    path: '/lit',
    name: 'Lit',
    component: () => import( '../views/Lit.vue')
  },
  {
    path: '/lit/:id',
    name: 'LitID',
    component: () => import('../views/LitID.vue'),
    props: true
  }
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
  //scrollBehavior() {
  // always scroll to top
  //return { top: 0 }
  //}
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0 })
        }, 50)
      })
    }
  },
})

router.beforeEach(( ) => {
  //let elemHeader = document.getElementById('page__header');
  //elemHeader.style.backgroundColor = 'rgba(254, 254, 254, .7)';
  //elemHeader.style.backdropFilter = "blur(20px)";
  //elemHeader.style.webkitBackdropFilter = "blur(20px)";
  //elemHeader.style.borderBottom = "1px solid #d5dddf"
  //elemHeader.style.transition = "0s"
})

export default router
