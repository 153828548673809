<template>

  <transition name="slideViewEnter" appear>

    <div class="home">

      <div id="page_container">

        <section class="section_container">
          <div class="section" id="section_1"></div>
        </section>

        <section class="section_text">
          <div class="section_text_wrapper" id="sec-text-1">
            <h3 class="section_text_title">Победа не важна, это единственное, что имеет значение</h3>
          </div>
        </section>

        <section class="section_container">
          <div class="section" id="section_2"></div>
        </section>

        <div class="section_text">
          <div class="section_text_wrapper" id="sec-text-2">
            <h3 class="section_text_title">Простое лучше, чем сложное</h3>
          </div>
        </div>

        <section class="section_container">
          <div class="section" id="section_3"></div>
        </section>

        <div class="section_text">
          <div class="section_text_wrapper" id="sec-text-3">
            <h3 class="section_text_title">Sul campo fino alla fine</h3>
          </div>
        </div>

        <section class="section_container">
          <div class="section" id="section_4"></div>
        </section>

      </div>

    </div>
  </transition>

</template>

<script>

export default {
  name: 'Home',

  components: {
  },

  data() {
    return {
    }},

  methods: {

  },

  mounted() {
  },

  beforeRouteLeave: function() {
  },

  routeEnter:  function() {
  }

}

</script>

<style scoped>

.home {
  width:                  100%;
  max-width:              100%;
}

#page_container {
  display:                grid;
  grid-template-columns:  1fr;
  grid-template-rows:     auto;
  width:                  100vw;
  max-width:              100vw;
  background:             #000;
}

.section_container {
  width:                  100%;
  background-color:       #fff;
  z-index:                0;
}

.section {
  width:                  100%;
  height:                 var(--heght-page);
  transition:             all 1s ease-out;
  background-position:    left center;
  background-size:        cover;
  background-repeat:      no-repeat;
}

#section_1 {
  background-image:       url(../assets/images/basketball-mini.png);
  margin-top:             3rem;
  background-position:    right center;
}
#section_2 {
  background-image:       url("../assets/images/girl-429380_1920.jpg");
}
#section_3 {
  background-image:       url("../assets/images/moon-2776955_1920.jpg");
}
#section_4 {
  background-image:       url("../assets/images/mountains-1920.jpg");
}

@media (min-width: 48rem) {
  #section_1 {
    background-image:       url(../assets/images/basketball-320.png);
    background-position:    left center;
  }
  #section_2 {
    background-image:       url("../assets/images/girl-429380_1920.jpg");
  }
  #section_3 {
    background-image:       url("../assets/images/moon-2776955_1920.jpg");
  }
  #section_4 {
    background-image:       url("../assets/images/mountains-1920.jpg");
  }
}

@media (min-width: 60rem) {
  #section_1 {
    background-image:       url(../assets/images/basketball-960.png);
    background-position:    left center;
  }
  #section_2 {
    background-image:       url("../assets/images/girl-429380_1920.jpg");
  }
  #section_3 {
    background-image:       url("../assets/images/moon-2776955_1920.jpg");
  }
  #section_4 {
    background-image:       url("../assets/images/mountains-1920.jpg");
  }
}

@media (min-width: 75rem) {
  #section_1 {
    background-image:       url(../assets/images/basketball-1280.png);
    background-position:    left center;
  }
  #section_2 {
    background-image:       url("../assets/images/girl-429380_1920.jpg");
  }
  #section_3 {
    background-image:       url("../assets/images/moon-2776955_1920.jpg");
  }
  #section_4 {
    background-image:       url("../assets/images/mountains-1920.jpg");
  }
}

.section_text {
  width:                  100vw;
  max-width:              100%;
  min-width:              100vw;
  height:                 auto;
  min-height:             30vh;
  transition:             .5s;
}

.section_text_wrapper {
  display:                grid;
  grid-template-columns:  1fr;
  max-width:              var(--width-wrapper-small);
  min-height:             100%;
  margin:                 auto;
  place-content:          center;
  padding-left:           .5rem;
  padding-right:          .5rem;
}

@media (min-width: 60rem) {
  .section_text_wrapper {
    max-width:              var(--width-wrapper-middle);
  }
}
@media (min-width: 75rem) {
  .section_text_wrapper {
    max-width:              var(--width-wrapper-large);
  }
}

.section_text_title {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  color: #fff;
}

</style>