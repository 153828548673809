import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    litItems: {},
    taskItems: {}
  },
  getters: {
    getLitItems (state) {
      return state.litItems
    },
    getTaskItems (state) {
      return state.taskItems
    }

  },
  mutations: {
    'SET_LITITEMS' (state, litItems) {
      state.litItems = litItems
    },
    'SET_TASKITEMS' (state, taskItems) {
      state.taskItems = taskItems
    }
  },
  actions: {
    initStore: ({ commit }) => {
      axios.get('/static/json/lit.json')
          .then((response) => {
            commit('SET_LITITEMS', response.data.litItems)
          })
      axios.get('/static/json/tasks.json')
          .then((response) => {
            commit('SET_TASKITEMS', response.data.taskItems)
          })
    }
  },
  modules: {
  }
})
