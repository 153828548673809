<template>

  <div id="page">

    <header id="page__header">

      <div id="page__header__wrapper__mini">

        <router-link to="/" id="page__header__left" tag="div" v-on:click="isMenu=false">
          <div id="page__header__logo">
            <img src="./assets/images/logo-b.png" v-if="!isLogoBlack" alt="ssair">
            <img src="./assets/images/logo-w.png" v-if="isLogoBlack" alt="ssair">
          </div>
        </router-link>

        <div id="page__header__center">

        </div>

        <a id="page__header__left" v-on:click="isMenu=!isMenu" style="cursor: pointer">
          <div id="page__header__dot">
            <img src="./assets/images/dot-g.png" v-if="!isLogoBlack" alt="ssair">
            <img src="./assets/images/dot.png" v-if="isLogoBlack" alt="ssair">
          </div>
        </a>

      </div>

      <div id="page__header__wrapper">

        <router-link to="/" id="page__header__left" tag="div">
          <div id="page__header__logo">
            <img src="./assets/images/logo-b.png" v-if="!isLogoBlack" alt="ssair">
            <img src="./assets/images/logo-w.png" v-if="isLogoBlack" alt="ssair">
          </div>
        </router-link>


        <div id="page__header__center">

        </div>


        <div id="page__header__right">

          <router-link to="/secret" class="page__header__right__item">
            <p style="color: #111" v-if="!isLogoBlack">Секрет</p>
            <p style="color: #fff" v-if="isLogoBlack">Секрет</p>
          </router-link>
          <router-link to="/sport" class="page__header__right__item">
            <p style="color: #111" v-if="!isLogoBlack">Спорт</p>
            <p style="color: #fff" v-if="isLogoBlack">Спорт</p>
          </router-link>
          <router-link to="/tasks" class="page__header__right__item">
            <p style="color: #111" v-if="!isLogoBlack">Задачи</p>
            <p style="color: #fff" v-if="isLogoBlack">Задачи</p>
          </router-link>
          <router-link to="/lit" class="page__header__right__item">
            <p style="color: #111" v-if="!isLogoBlack">Стихи</p>
            <p style="color: #fff" v-if="isLogoBlack">Стихи</p>
          </router-link>

        </div>


      </div>

    </header>

    <div id="header_show" v-on:scroll.passive="showHeader"></div>

    <main id="page__main">
      <transition name="d_d_move" appear>
      <div id="page__menu" v-if="isMenu">
        <router-link to="/secret" class="page__header__right__item" v-on:click="isMenu=false">
          <p style="color: #fff">Секрет</p>
        </router-link>
        <router-link to="/sport" class="page__header__right__item" v-on:click="isMenu=false">
          <p style="color: #fff">Спорт</p>
        </router-link>
        <router-link to="/tasks" class="page__header__right__item" v-on:click="isMenu=false">
          <p style="color: #fff">Задачи</p>
        </router-link>
        <router-link to="/lit" class="page__header__right__item" v-on:click="isMenu=false">
          <p style="color: #fff">Стихи</p>
        </router-link>
      </div>
      </transition>
      <router-view></router-view>
    </main>

    <footer id="page__footer">
      2017-2025
    </footer>

  </div>

</template>

<script>

export default {

  data() {
    return {
      isVisibleSchoolMenu: true,
      isVisibleHeader: true,
      isLogoBlack: true,
      isMenu: false
    }},
  methods: {
    showHeader: function () {
      let elem = document.getElementById('header_show');
      let elemHeader = document.getElementById('page__header');
      let position = elem.getBoundingClientRect();
      let y = position.top;
      if (y < -300){
        this.isLogoBlack = false
        elemHeader.style.backgroundColor = 'rgba(254, 254, 254, .5)';// Изменить на 0
        elemHeader.style.backdropFilter = "blur(20px)";
        elemHeader.style.webkitBackdropFilter = "blur(20px)";
        elemHeader.style.transition = ".5s"
      }
      if ( y >= -300) {
        this.isLogoBlack = true
        elemHeader.style.backgroundColor = 'rgba(0, 0, 0, 1)';
        elemHeader.style.backdropFilter = "blur(0px)";
        elemHeader.style.webkitBackdropFilter = "blur(0px)";
      }
    },
  },
  created: function () {
    this.$store.dispatch('initStore')
  },
  mounted() {
    window.addEventListener("scroll", this.showHeader);
    this.showHeader()
  }
}
</script>

<style>

@import url("https://fonts.googleapis.com/css?family=Exo+2:200|Jost|Noto+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


@import './css/reset.module.css';

:root {
  box-sizing:             border-box;
  font-size:              16px;
  font-family:            'IBM Plex Sans', sans-serif;

  --header-height:          3rem;
  --header-height-small:    2.5rem;

  --width-wrapper-small:    95vw;
  --width-wrapper-middle:   90vw;
  --width-wrapper-large:    75rem; /*1024px;*/

  --heght-page:             calc(100vh - 3rem);

  --header-font-size:       1.875rem;
  --header-font-size-large: 2.5rem;

  --menu-item-font-size-20:     1.25rem;
  --menu-item-font-size-25:     1.5625rem;
  --menu-item-font-size-30:     1.875rem;
  --menu-item-font-size-33:     2.0625rem;
  --menu-item-font-size-40:     2.5rem;

  --font-size:            1.8rem;
  --font-size-middle:     1.4rem;
  --font-size-small:      1.2rem;
  --font-size-x-small:    1rem;
  --font-size-xx-small:   0.8rem;
}

body {
  position:               relative;
  min-height:             100vh;
}

html {
  scroll-behavior:        smooth;
}

#page {
  display:                grid;
  grid-template-columns:  1fr;
  grid-template-rows:     auto;
  max-width:              100vw;
}

#page__header  {
  width:                  100vw;
  max-width:              100%;
  height:                 var(--header-height);
  position:               fixed;
  top:                    0;
  /*
  place-content:          center;
  place-items:            center;

  border-bottom:          1px solid #bbb;
  */
  z-index:                1000;
  transition:             .5s;
}

#page__header__wrapper__mini {
  display: grid;
  grid-template-columns:  3rem 1fr 3rem;;
  max-width: var(--width-wrapper-small);
  min-height: 100%;
  margin: auto;
  place-content: center;
  place-items: center;
  padding-left: .5rem;
  padding-right: .5rem;
}

#page__header__wrapper {
  display: none;
}
@media (min-width: 36rem) {

  #page__header__wrapper__mini {
    display: none;
  }

  #page__header__wrapper {
    display: grid;
    grid-template-columns:  3rem 1fr 18rem;
    max-width: var(--width-wrapper-small);
    min-height: 100%;
    margin: auto;
    place-content: center;
    place-items: center;
    padding-left: .5rem;
    padding-right: .5rem;
  }
}
@media (min-width: 60rem) {
  #page__header__wrapper {
    grid-template-columns:  3rem 1fr 24rem;
    max-width:              var(--width-wrapper-middle);
  }
}
@media (min-width: 75rem) {
  #page__header__wrapper {
    max-width:              var(--width-wrapper-large);
  }
}

#page__header__left {
  display:                grid;
  grid-template-columns:  var(--header-height);
  height:                 var(--header-height);
  max-height:             var(--header-height);
  min-width:              var(--header-height);
  justify-self:           center;
  align-self:             center;
  justify-items:          center;

}

@media (min-width: 60rem) {
  #page__header__left {
  }
}

#page__header__logo {
  display:                grid;
  grid-template-columns:  var(--header-height);
  grid-template-rows:     var(--header-height);
  height:                 var(--header-height);
  max-height:             var(--header-height);
  width:                  var(--header-height);
  background:             none;
  place-content:          center;
  place-items:            center;
  justify-self:           center;
  align-self:             center;
  justify-items:          center;
}

#page__header__logo > img {
  width:                  var(--header-height);
  height:                 calc(var(--header-height)/2);
  min-width:              100%;
  justify-self:           center;
  align-self:             center;
  object-fit:             cover;
  justify-items:          center;

}

#page__header__dot {
  display:                grid;
  grid-template-columns:  var(--header-height);
  grid-template-rows:     var(--header-height);
  height:                 var(--header-height);
  max-height:             var(--header-height);
  width:                  var(--header-height);
  background:             none;
  place-content:          center;
  place-items:            center;
  justify-self:           center;
  align-self:             center;
  justify-items:          center;
}

#page__header__dot > img {
  width:                  var(--header-height);
  height:                 calc(var(--header-height)/2);
  min-width:              100%;
  justify-self:           center;
  align-self:             center;
  object-fit:             contain;
  justify-items:          center;
}

@media (min-width: 60rem) {
  #page__header__logo {
  }
  #page__header__logo > img {
  }
}

#page__header__center{
  min-width:                100%;
  min-height:               100%;
}

#page__header__right {
  display:                grid;
  grid-template-columns:  auto auto auto auto;
  height:                 var(--header-height);
  max-height:             var(--header-height);
  min-height:             var(--header-height);
  place-content:          center;
  place-items:            center;
  justify-self:           flex-end;
  grid-gap:               22px;
}

.page__header__right__item {
  display:                grid;
  place-content:          center;
  place-items:            center;
  place-self:             center;
  min-height:             100%;
  min-width:              100%;
  font-weight:            300;
  transition:             .3s;
  font-size:              var(--menu-item-font-size-20);
  color:                  #333;
  justify-items:          center;
  justify-content:        center;
}

.page__header__right__item:hover {
  color:                  #999;
}

@media (hover: none) {
  .page__header__right__item:hover {
    color:                  #333;
  }
}

@media (min-width: 60rem) {
  #page__header__right {
    grid-gap: 29px;
  }
}

#page__main {
  display: 			          grid;
  max-width:              100%;
}

#page__menu {
  display:                  grid;
  height: 30vh;
  min-height: 30vh;
  width: 100vw;
  min-width: 100vw;
  background-color: #000000;
  position: fixed;
  top: var(--header-height);
  z-index: 999;
}

@media (min-width: 36rem) {
  #page__menu {
    display: none;
  }
}

#page__footer {
  display: 			          grid;
  background-color:       rgba( 0, 0, 0, 1);
  align-items:            center;
  justify-items:          center;
  align-content:          center;
  color:                  #fff;
  width:                  100%;
  max-width:              100%;
  min-height:             var(--header-height-small);
  max-height:             var(--header-height-small);
  font-size:              var(--menu-item-font-size-20);
}

@media (min-width: 60rem) {
  #page__footer {
    display: 			          grid;
    background-color:       rgba( 0, 0, 0, 1);
    align-items:            center;
    justify-items:          center;
    align-content:          center;
    color:                  #fff;
    width:                  100%;
    max-width:              100%;
    min-height:             var(--header-height);
    max-height:             var(--header-height);
    font-size:              var(--menu-item-font-size-20);
  }
}

.logo-enter-active {
  animation: logo-in 3s;
}

@keyframes logo-in {
  from {
    transform:  rotate3d(0, 1, 0, -360deg);
    animation-timing-function: linear;
  }

  50% {
    transform:  rotate3d(0, 1, 0, -360deg);
    animation-timing-function: linear;
  }

  74% {
    transform:  rotate3d(0, 1, 0, -180deg);
    animation-timing-function: linear;
  }

  to {
    transform:  rotate3d(0, 1, 0, 0deg);
    animation-timing-function: linear;
  }
}

.d_d_move-enter-active {
  transition: all .3s ease-out;
}

.d_d_move-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.d_d_move-enter-from,
.d_d_move-leave-to {
  transform: translateY(-50vh);
}

.slideViewEnter-enter-active,
.slidePageStart-leave-active {
  transition: opacity .8s ease;
}

.slideViewEnter-enter-from,
.slidePageStart-leave-to {
  opacity: 0;
}

</style>
